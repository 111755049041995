var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('a-row',[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"Nome","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'first_name',
						{
							initialValue: _vm.user.first_name,
							rules: [
								{
									required: true,
									message: 'Por favor, informe um nome!',
								} ],
						} ]),expression:"[\n\t\t\t\t\t\t'first_name',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\tinitialValue: user.first_name,\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage: 'Por favor, informe um nome!',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"placeholder":"Informe o seu nome"}})],1),_c('a-form-item',{attrs:{"label":"Sobrenome","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'last_name',
						{
							initialValue: _vm.user.last_name,
							rules: [
								{
									required: true,
									message:
										'Por favor, informe seu sobrenome!',
								} ],
						} ]),expression:"[\n\t\t\t\t\t\t'last_name',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\tinitialValue: user.last_name,\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t'Por favor, informe seu sobrenome!',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"placeholder":"Informe o seu sobrenome"}})],1),_c('a-form-item',{attrs:{"label":"Email","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'email',
						{
							initialValue: _vm.user.email,
							rules: [
								{
									type: 'email',
									message: 'Este email não é válido!',
								},
								{
									required: true,
									message: 'Por favor, informe um email!',
								} ],
						} ]),expression:"[\n\t\t\t\t\t\t'email',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\tinitialValue: user.email,\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\ttype: 'email',\n\t\t\t\t\t\t\t\t\tmessage: 'Este email não é válido!',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage: 'Por favor, informe um email!',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"}],attrs:{"placeholder":"Informe o email de acesso para o seu usuário","disabled":""}})],1),_c('a-form-item',{attrs:{"label":"Celular (whatsapp)","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'phone',
						{
							initialValue: _vm.user.phone,
							rules: [
								{
									required: true,
									message:
										'Por favor, informe um número de celular!',
								},
								{
									pattern: /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/,
									message:
										'Por favor, informe um número válido',
								} ],
						} ]),expression:"[\n\t\t\t\t\t\t'phone',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\tinitialValue: user.phone,\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t'Por favor, informe um número de celular!',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\tpattern: /^(?:(?:\\+|00)?(55)\\s?)?(?:\\(?([1-9][0-9])\\)?\\s?)?(?:((?:9\\d|[2-9])\\d{3})\\-?(\\d{4}))$/,\n\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t'Por favor, informe um número válido',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"},{name:"mask",rawName:"v-mask",value:(['(##) ####-####', '(##) #####-####']),expression:"['(##) ####-####', '(##) #####-####']"}],attrs:{"placeholder":"Informe o número do celular para o seu usuário"}})],1),_c('a-form-item',{attrs:{"label":"CPF","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
						'cpf',
						{
							initialValue: _vm.user.cpf,
							rules: [
								{
									required: true,
									message:
										'Por favor, informe o número do seu CPF!',
								},
								{
									validator: _vm.onValidateCPF,
								} ],
						} ]),expression:"[\n\t\t\t\t\t\t'cpf',\n\t\t\t\t\t\t{\n\t\t\t\t\t\t\tinitialValue: user.cpf,\n\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t'Por favor, informe o número do seu CPF!',\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\tvalidator: onValidateCPF,\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t],\n\t\t\t\t\t\t},\n\t\t\t\t\t]"},{name:"mask",rawName:"v-mask",value:('###.###.###-##'),expression:"'###.###.###-##'"}],attrs:{"placeholder":"Informe o seu CPF"}})],1),_c('a-row',{attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":12}},[_c('a-form-item',{attrs:{"label":"RG/CNH","has-feedback":""}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'rg_number',
								{
									initialValue: _vm.user.rg_number,
									rules: [
										{
											required: true,
											message:
												'Por favor, informe o numero do RG/CNH!',
										},
										{
											max: 11,
											message:
												'Limite máximo de 11 caracteres',
										} ],
								} ]),expression:"[\n\t\t\t\t\t\t\t\t'rg_number',\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\tinitialValue: user.rg_number,\n\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t\t'Por favor, informe o numero do RG/CNH!',\n\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\tmax: 11,\n\t\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t\t'Limite máximo de 11 caracteres',\n\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t]"}],attrs:{"placeholder":"Informe somente números para RG/CNH","type":"tel"}})],1)],1),_c('a-col',{attrs:{"span":8}},[_c('a-form-item',{attrs:{"label":"Emissor","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'rg_agency',
								{
									initialValue: _vm.user.rg_agency,
									rules: [
										{
											required: true,
											message:
												'Por favor, selecione um orgão emissor!',
										} ],
								} ]),expression:"[\n\t\t\t\t\t\t\t\t'rg_agency',\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\tinitialValue: user.rg_agency,\n\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t\t'Por favor, selecione um orgão emissor!',\n\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t]"}],staticClass:"select",attrs:{"placeholder":"Selecione um orgão emissor"}},_vm._l((_vm.emitterList),function(emitter,index){return _c('a-select-option',{key:index,attrs:{"value":emitter[0]}},[_vm._v(" "+_vm._s(emitter[0] + ' - ' + emitter[1])+" ")])}),1)],1)],1),_c('a-col',{attrs:{"span":4}},[_c('a-form-item',{attrs:{"label":"Estado","has-feedback":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
								'rg_state',
								{
									initialValue: _vm.user.rg_state,
									rules: [
										{
											required: true,
											message:
												'Por favor, selecione um estado!',
										} ],
								} ]),expression:"[\n\t\t\t\t\t\t\t\t'rg_state',\n\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\tinitialValue: user.rg_state,\n\t\t\t\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t\t\t\t{\n\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\tmessage:\n\t\t\t\t\t\t\t\t\t\t\t\t'Por favor, selecione um estado!',\n\t\t\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t\t\t],\n\t\t\t\t\t\t\t\t},\n\t\t\t\t\t\t\t]"}],staticClass:"select",attrs:{"placeholder":"Selecione um estado"}},_vm._l((_vm.stateList),function(state){return _c('a-select-option',{key:state,attrs:{"value":state}},[_vm._v(" "+_vm._s(state)+" ")])}),1)],1)],1)],1),_c('a-row',{staticStyle:{"flex-direction":"row"},attrs:{"type":"flex","gutter":16}},[_c('a-form-item',{staticClass:"sign-wpp"},[_vm._v(" Declaro que aceito receber contato via whatsapp "),_c('a-switch',{staticStyle:{"margin-left":"16px"},attrs:{"size":"small"},on:{"change":_vm.agreeContactChange},model:{value:(_vm.agree_contact),callback:function ($$v) {_vm.agree_contact=$$v},expression:"agree_contact"}})],1)],1),_c('a-row',{staticClass:"buttons",attrs:{"type":"flex","justify":"center"}},[_c('a-form-item',[_c('a-col',[_c('a-button',{staticStyle:{"width":"400px"},attrs:{"type":"primary","loading":_vm.isLoading},on:{"click":_vm.handleSubmit}},[_vm._v(" Continuar ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }