<template>
	<div class="container">
		<a-row>
			<a-form :form="form">
				<a-form-item label="Nome" has-feedback>
					<a-input
						v-decorator="[
							'first_name',
							{
								initialValue: user.first_name,
								rules: [
									{
										required: true,
										message: 'Por favor, informe um nome!',
									},
								],
							},
						]"
						placeholder="Informe o seu nome"
					/>
				</a-form-item>
				<a-form-item label="Sobrenome" has-feedback>
					<a-input
						v-decorator="[
							'last_name',
							{
								initialValue: user.last_name,
								rules: [
									{
										required: true,
										message:
											'Por favor, informe seu sobrenome!',
									},
								],
							},
						]"
						placeholder="Informe o seu sobrenome"
					/>
				</a-form-item>
				<a-form-item label="Email" has-feedback>
					<a-input
						v-decorator="[
							'email',
							{
								initialValue: user.email,
								rules: [
									{
										type: 'email',
										message: 'Este email não é válido!',
									},
									{
										required: true,
										message: 'Por favor, informe um email!',
									},
								],
							},
						]"
						placeholder="Informe o email de acesso para o seu usuário"
						disabled
					/>
				</a-form-item>
				<a-form-item label="Celular (whatsapp)" has-feedback>
					<a-input
						v-decorator="[
							'phone',
							{
								initialValue: user.phone,
								rules: [
									{
										required: true,
										message:
											'Por favor, informe um número de celular!',
									},
									{
										pattern: /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/,
										message:
											'Por favor, informe um número válido',
									},
								],
							},
						]"
						v-mask="['(##) ####-####', '(##) #####-####']"
						placeholder="Informe o número do celular para o seu usuário"
					/>
				</a-form-item>
				<a-form-item label="CPF" has-feedback>
					<a-input
						v-decorator="[
							'cpf',
							{
								initialValue: user.cpf,
								rules: [
									{
										required: true,
										message:
											'Por favor, informe o número do seu CPF!',
									},
									{
										validator: onValidateCPF,
									},
								],
							},
						]"
						v-mask="'###.###.###-##'"
						placeholder="Informe o seu CPF"
					/>
				</a-form-item>
				<a-row :gutter="16">
					<a-col :span="12">
						<a-form-item label="RG/CNH" has-feedback>
							<a-input
								v-decorator="[
									'rg_number',
									{
										initialValue: user.rg_number,
										rules: [
											{
												required: true,
												message:
													'Por favor, informe o numero do RG/CNH!',
											},
											{
												max: 11,
												message:
													'Limite máximo de 11 caracteres',
											},
										],
									},
								]"
								placeholder="Informe somente números para RG/CNH"
								type="tel"
							/>
						</a-form-item>
					</a-col>
					<a-col :span="8">
						<a-form-item label="Emissor" has-feedback>
							<a-select
								v-decorator="[
									'rg_agency',
									{
										initialValue: user.rg_agency,
										rules: [
											{
												required: true,
												message:
													'Por favor, selecione um orgão emissor!',
											},
										],
									},
								]"
								placeholder="Selecione um orgão emissor"
								class="select"
							>
								<a-select-option
									v-for="(emitter, index) in emitterList"
									:key="index"
									:value="emitter[0]"
								>
									{{ emitter[0] + ' - ' + emitter[1] }}
								</a-select-option>
							</a-select>
						</a-form-item>
					</a-col>
					<a-col :span="4">
						<a-form-item label="Estado" has-feedback>
							<a-select
								v-decorator="[
									'rg_state',
									{
										initialValue: user.rg_state,
										rules: [
											{
												required: true,
												message:
													'Por favor, selecione um estado!',
											},
										],
									},
								]"
								placeholder="Selecione um estado"
								class="select"
							>
								<a-select-option
									v-for="state in stateList"
									:key="state"
									:value="state"
								>
									{{ state }}
								</a-select-option>
							</a-select>
						</a-form-item>
					</a-col>
				</a-row>
				<a-row type="flex" style="flex-direction: row;" :gutter="16">
					<a-form-item class="sign-wpp">
						Declaro que aceito receber contato via whatsapp
						<a-switch
							v-model="agree_contact"
							size="small"
							style="margin-left: 16px;"
							@change="agreeContactChange"
						/>
					</a-form-item>
				</a-row>
				<a-row type="flex" justify="center" class="buttons">
					<a-form-item>
						<a-col>
							<a-button
								type="primary"
								style="width: 400px;"
								:loading="isLoading"
								@click="handleSubmit"
							>
								Continuar
							</a-button>
						</a-col>
					</a-form-item>
				</a-row>
			</a-form>
		</a-row>
	</div>
</template>

<script>
import { emitterList, stateList } from '@/assets/emitterStateList'
import { mask } from 'vue-the-mask'
import { validateCPF } from '@/utils/utils'
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'PartnerInfo',
	directives: { mask },
	data() {
		return {
			emitterList,
			stateList,
			form: this.$form.createForm(this, { name: 'step1-form-1' }),
			userInfo: {},
			agree_contact: true,
		}
	},
	computed: {
		...mapGetters('account', ['user']),
		...mapGetters('merchants', ['isLoading']),
	},
	watch: {
		$route: {
			immediate: true,
			handler: 'getUser',
		},
	},
	methods: {
		...mapActions('account', ['getUser', 'updateUser']),
		onValidateCPF(rule, value, callback) {
			const valid = value && value.length == 14 && !validateCPF(value)
			const msg = 'Por favor, informe um CPF válido!'

			valid ? callback(msg) : callback()
		},
		agreeContactChange(checked) {
			this.agree_contact = checked
		},
		handleSubmit() {
			this.form.validateFieldsAndScroll(async (err, values) => {
				if (!err) {
					values.agree_contact = this.agree_contact
					await this.updateUser(values)
					this.$router.push('/steps/company-info/')
				}
			})
		},
	},
}
</script>

<style lang="less" scoped>
.container {
	font-weight: bold;
	color: rgba(0, 0, 0, 0.65);

	.sign-wpp {
		width: 100%;
		margin: 15px 0 0 10px;
		color: #3574b6;
	}

	.select {
		font-weight: normal;
	}

	.buttons {
		margin: 40px auto 10px;
	}

	.alert {
		margin-bottom: 15px;
		border-radius: 5px;
	}
}
</style>
